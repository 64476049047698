import "./UpdatePage.css";

import { useEffect } from "react";
import { IonPage, IonContent } from "@ionic/react";
import { Trans } from "react-i18next";
import OnewsLogo from "../../components/OnewsLogo/onewsLogo";

function UpdatePage() {
    useEffect(() => {
        localStorage.removeItem("updateVersion");
        window.location.reload(true);
    }, []);

    return (
        <IonPage>
            <IonContent>
                <div className="updateContainer">
                    <OnewsLogo size={200} textSize={30} className="loginLogo" />
                    <div className="updatePageMessage">
                        <Trans>Update.Updating</Trans>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
}

export default UpdatePage;
