import React from "react";
import { IonLabel, IonTitle } from "@ionic/react";
import { isStrRTL } from "../../logic/languageHelpers";
import innerText from "../../logic/rsx-innerHtml";

function FriendlyRtlContainer(props) {
    let text = innerText(props.children);
    return (
        <div style={{ direction: isStrRTL(text) ? "rtl" : "ltr" }} {...props}>
            {props.children}
        </div>
    );

}

function FriendlyIonLabel(props) {
    return (
        <IonLabel dangerouslySetInnerHTML={{ __html: props.children }} />
    );
}

function FriendlyIonTitle(props) {
    return (
        <IonTitle dangerouslySetInnerHTML={{ __html: props.children }} />
    );
}

function FriendlyDiv(props) {
    return (
        <div dangerouslySetInnerHTML={{ __html: props.children }} />
    );
}

export {
    FriendlyRtlContainer,
    FriendlyIonLabel,
    FriendlyIonTitle,
    FriendlyDiv
};
