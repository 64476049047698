import { format, differenceInDays, formatDistanceToNow } from "date-fns";

function displayDate(date) {
    const now = new Date();
    let d = new Date(date);
    let daysDiff = differenceInDays(now, d);

    if (daysDiff < 3) {
        return formatDistanceToNow(d, { includeSeconds: true });
    } else if (daysDiff <= 5) {
        return `${format(d, "EEEE")} at ${format(d, "HH:mm")}`;
    }
    return format(d, "dd/MM/yyyy HH:mm");
}

export { displayDate };
