const TOKEN_KEY = "token",
    REFRESH_TOKEN_KEY = "refreshToken";

const storeToken = (token, refreshToken) => {
    localStorage.setItem(TOKEN_KEY, token);
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
};

const getToken = () => {
    const tokens = {
        token: localStorage.getItem(TOKEN_KEY),
        refreshToken: localStorage.getItem(REFRESH_TOKEN_KEY),
    };
    return tokens;
};

const getDecodedToken = (jwt) => {
    const jwtArr = getToken().token.split(".");
    if (jwtArr.length !== 3) return null; // invalid token
    
    const result = new Array(2);

    result[0] = decodeTokenPart(
        jwtArr[0].replace(/-/g, "+").replace(/_/g, "/")
    );
    result[1] = decodeTokenPart(
        jwtArr[1].replace(/-/g, "+").replace(/_/g, "/")
    );

    return result;
};

const decodeTokenPart = (str) => {
    try {
        return JSON.parse(
            decodeURIComponent(
                atob(str)
                    .split("")
                    .map(function (c) {
                        return (
                            "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
                        );
                    })
                    .join("")
            )
        );
    }
    catch (e) {
        return null;
    }
};

export { storeToken, getToken, getDecodedToken };
