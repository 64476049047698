import "./Settings.css";

import React, { useContext } from "react";
import {
    IonList,
    IonItem,
    IonLabel,
    IonSelect,
    IonSelectOption,
    IonButton
} from "@ionic/react";
import { useHistory } from "react-router";
import i18next from "i18next";
import { Trans, useTranslation } from "react-i18next";
import UserContext from "../../context/userContext";
import AppPage from "../AppPage/AppPage";
import { logout } from "../../logic/api";

const Settings = () => {
    const currentUser = useContext(UserContext);
    const history = useHistory();
    const { t } = useTranslation();
    // useEffect(() => {}, []);

    const handleUiLanguageChange = async (language) => {
        await i18next.changeLanguage(language);
        currentUser.changeLanguage(language);
    };

    const handleLogout = () => {
        logout();
        currentUser.reloadAppState();
    };

    return (
        <AppPage title={t("Settings.Title")}>
            <IonList>
                <IonItem>
                    <IonLabel>
                        <Trans>Settings.Language</Trans>
                    </IonLabel>
                    <IonSelect
                        //value={hairColor}
                        okText="Okay"
                        cancelText="Dismiss"
                        onIonChange={(e) =>
                            handleUiLanguageChange(e.detail.value)
                        }
                    >
                        <IonSelectOption value="en">
                            English (United States)
                        </IonSelectOption>
                        <IonSelectOption value="he">עברית</IonSelectOption>
                    </IonSelect>
                </IonItem>

                    <IonButton onClick={handleLogout} expand="block" fill="solid" color="danger" style={{marginTop: "40px"}}>
                        <Trans>Menu.LogOut</Trans>
                    </IonButton>
            </IonList>
        </AppPage>
    );
};

export default Settings;
