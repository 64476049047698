import "./SideMenu.css";
import pages from "../../../configuration/pages.json";

import React, { useState } from "react";
import {
    IonMenu,
    IonContent,
    IonList,
    IonItem,
    IonIcon,
    IonLabel,
    IonListHeader,
    IonNote,
    IonMenuToggle,
} from "@ionic/react";
import ProfilePicture from "../../ProfilePicture/ProfilePicture";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import {
    personOutline,
    albumsOutline,
    peopleOutline,
    settingsOutline,
    logOutOutline,
    newspaperOutline,
    bookmarkOutline,
} from "ionicons/icons";
import { getUserDetails } from "../../../services/api/authAPI";
import { useTranslation } from "react-i18next";
import { logout, getTokens } from "../../../logic/api";
import i18next from "i18next";

function SideMenu() {
    const location = useLocation();
    const history = useHistory();
    const { t } = useTranslation();
    const userDetails = getUserDetails();
    const isDisabled = getTokens() == null ? true : false; //location.pathname === "/";

    const icons = {
        personOutline,
        albumsOutline,
        peopleOutline,
        settingsOutline,
        newspaperOutline,
        bookmarkOutline,
    };

    const handleLogout = () => {
        logout();
        history.replace("/");
    };

    return (
        <IonMenu
            side={i18next.dir() === "ltr" ? "start" : "end"}
            contentId="main"
            type="overlay"
            disabled={isDisabled}
        >
            <IonContent>
                <IonItem lines="none">
                    <div className="menuHeader">
                        <ProfilePicture />
                    </div>
                </IonItem>
                <IonListHeader>{userDetails?.name}</IonListHeader>
                <IonNote className="menu-note">{userDetails?.email}</IonNote>
                <IonList>
                    {pages.map((appPage, index) => {
                        return (
                            <IonMenuToggle key={index} autoHide={false}>
                                <IonItem
                                    className={
                                        location.pathname === appPage.url
                                            ? "selected"
                                            : ""
                                    }
                                    routerLink={appPage.url}
                                    routerDirection="none"
                                    lines={
                                        index === pages.length - 1
                                            ? "full"
                                            : "none"
                                    }
                                    detail={false}
                                >
                                    <IonIcon
                                        slot="start"
                                        ios={icons[appPage.iosIcon]}
                                        md={icons[appPage.mdIcon]}
                                    />
                                    <IonLabel>{t(appPage.title)}</IonLabel>
                                </IonItem>
                            </IonMenuToggle>
                        );
                    })}
                </IonList>

                <IonList>
                    <IonItem lines="none" detail={false} onClick={handleLogout}>
                        <IonIcon
                            slot="start"
                            ios={logOutOutline}
                            md={logOutOutline}
                        />
                        <IonLabel>{t("Menu.LogOut")}</IonLabel>
                    </IonItem>
                </IonList>
            </IonContent>
        </IonMenu>
    );
}

export default SideMenu;
