import "./Article.css";
import arabWriter from "../../images/arab_writer.jpg";

import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import {
    IonPage,
    IonContent,
    IonIcon,
    IonChip,
    IonLabel,
    IonAvatar,
    IonHeader,
    IonToolbar,
    IonSkeletonText,
} from "@ionic/react";
import { arrowBackOutline, timeOutline } from "ionicons/icons";
import { Trans } from "react-i18next";
import { displayDate } from "../../logic/datetime";
import imagesApi from "../../services/api/imagesAPI";
import { FriendlyRtlContainer, FriendlyIonLabel, FriendlyIonTitle, FriendlyDiv } from "../../components/FriendlyHtmlElements/FriendlyHtmlElemets";

function Article(props) {
    const location = useLocation();
    const history = useHistory();
    const [toolbarOpacity, setToolbarOpacity] = useState(0);
    const [showImage, setShowImage] = useState(true);
    const [article, setArticle] = useState(null);

    const handleGoBack = () => {
        history.goBack();
    };

    const handleContentScroll = (e) => {
        let opacity = e.detail.scrollTop / 35;
        opacity = opacity < 0 ? 0 : opacity;
        setToolbarOpacity(opacity);
    };

    const getText = (text) => {
        let paragraphs = text.split("\r\n");
        let result = "";
        for (let paragraph of paragraphs) {
            result += `<p>${paragraph}</p>`;
        }
        return result;
    };

    const imageError = (e) => {
        setShowImage(false);
    };

    useEffect(() => {
        if (location.state.article) {
            setArticle(location.state.article);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <IonPage>
            <IonContent
                fullscreen
                scrollEvents
                onIonScroll={handleContentScroll}
            >
                <div className="pages_article_toolbarContainer">
                    <IonHeader translucent style={{ opacity: toolbarOpacity }}>
                        <IonToolbar>
                            <FriendlyIonTitle>{article?.title}</FriendlyIonTitle>
                        </IonToolbar>
                    </IonHeader>
                </div>

                <header className="articleHeaderContainer ion-padding">
                    { showImage && (
                        <div className="articleHeaderImageContainer">
                            {(article?.medias?.length > 0 && (
                                <img
                                    src={imagesApi.getImageUrl(
                                        article?.medias[0].filename
                                    )}
                                    alt="header"
                                    loading="lazy"
                                    className="articleHeaderImage"
                                    onError={imageError}
                                />
                            )) || <></>}
                        </div>
                    ) || <></>}
                </header>

                <div className="backButtonContainer">
                    <IonIcon
                        icon={arrowBackOutline}
                        className="backIcon"
                        onClick={handleGoBack}
                        // style={{ opacity: backButtonOpacity }}
                    />
                </div>

                <div>
                    <div className="articleTitleContainer ion-padding">
                        {(article && (
                            <>
                                {(article.category && (
                                    <div>
                                        <IonChip>
                                            <IonLabel>
                                                {article.category.name}
                                            </IonLabel>
                                        </IonChip>
                                    </div>
                                )) || <></>}
                                
                                <FriendlyRtlContainer className="page_article_articleTitle">
                                    <FriendlyIonLabel>ABC{article.title}</FriendlyIonLabel>
                                </FriendlyRtlContainer>
                                
                                <FriendlyRtlContainer className="page_article_subTitle">
                                    <FriendlyIonLabel>{article.subTitle}</FriendlyIonLabel>
                                </FriendlyRtlContainer>
                            </>
                        )) || (
                            <div className="pages_article_headerContentLoadingContainer">
                                <IonSkeletonText
                                    animated
                                    style={{ width: "50%" }}
                                />
                                <IonSkeletonText
                                    animated
                                    style={{ width: "80%" }}
                                />
                                <IonSkeletonText
                                    animated
                                    style={{ width: "60%" }}
                                />
                            </div>
                        )}
                    </div>

                    <div className="articleTextContainer ion-padding">
                        {(article && (
                            <>
                                {(article.writer && (
                                    <IonChip className="writerNameChip">
                                        <IonAvatar>
                                            <img
                                                src={arabWriter}
                                                alt="writer"
                                            />
                                        </IonAvatar>
                                        <IonLabel>
                                            {article.writer.name}
                                        </IonLabel>
                                    </IonChip>
                                )) || <></>}
                                <IonChip>
                                    <IonIcon icon={timeOutline} />
                                    <IonLabel>
                                        {displayDate(article.publishDate)}
                                    </IonLabel>
                                </IonChip>

                                <FriendlyRtlContainer>
                                    <FriendlyDiv>{getText(article.text)}</FriendlyDiv>
                                </FriendlyRtlContainer>
                                
                                <Trans>Article.Source</Trans>:{" "}
                                <a
                                    href={article.url}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {article.url}
                                </a>
                            </>
                        )) || (
                            <>
                                <IonSkeletonText
                                    animated
                                    style={{ width: "50%" }}
                                />
                                <IonSkeletonText
                                    animated
                                    style={{ width: "80%" }}
                                />
                                <IonSkeletonText
                                    animated
                                    style={{ width: "60%" }}
                                />
                                <br />
                                <IonSkeletonText
                                    animated
                                    style={{ width: "50%" }}
                                />
                                <IonSkeletonText
                                    animated
                                    style={{ width: "80%" }}
                                />
                                <IonSkeletonText
                                    animated
                                    style={{ width: "60%" }}
                                />
                                <br />
                                <IonSkeletonText
                                    animated
                                    style={{ width: "50%" }}
                                />
                                <IonSkeletonText
                                    animated
                                    style={{ width: "80%" }}
                                />
                                <IonSkeletonText
                                    animated
                                    style={{ width: "60%" }}
                                />
                            </>
                        )}
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
}

export default Article;
