import axios from "axios";
import config from "../configuration";

const parseJwt = (jwt) => {
    const jwtArr = jwt.split(".");
    const result = new Array(2);

    result[0] = decodeTokenPart(
        jwtArr[0].replace(/-/g, "+").replace(/_/g, "/")
    );
    result[1] = decodeTokenPart(
        jwtArr[1].replace(/-/g, "+").replace(/_/g, "/")
    );

    return result;
};

const decodeTokenPart = (str) => {
    return JSON.parse(
        decodeURIComponent(
            atob(str)
                .split("")
                .map(function (c) {
                    return (
                        "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
                    );
                })
                .join("")
        )
    );
};

const login = async (username, password) => {
    const formData = new FormData();
    formData.append("username", username);
    formData.append("password", password);

    try {
        const { data } = await axios.post(`${config.ApiUrl}/login`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });

        const TOKEN_KEY = "token",
            REFRESH_TOKEN_KEY = "refreshToken";

        localStorage.setItem(TOKEN_KEY, data.access_token);
        localStorage.setItem(REFRESH_TOKEN_KEY, data.refresh_token);

        return [200, data.access_token, data.refresh_token];
    } catch (ex) {
        let status = 0;
        if (ex.response === null || ex.response.status === 500) status = 500;
        else status = ex.response.status;
        return [status, null, null];
    }
};

const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
};

const getTokens = () => {
    const token = localStorage.getItem("token"),
        refreshToken = localStorage.getItem("refreshToken");

    if (!token || !refreshToken) return null;

    return { token, refreshToken };
};

const hasValidToken = () => {
    const tokens = getTokens();
    if (!tokens) return false;

    const [, payload] = parseJwt(tokens.token);
    return Date.now() < payload.exp * 1000;
};

const useApi = () => {
    const instance = axios.create({
        baseURL: config.ApiUrl,
        //headers: { Authorization: `Bearer ${token}` },
        validateStatus: (status) => {
            if (status === 401) {
                console.log("unauthorized request to onews");
                // logout();
                // history.replace("/");
            }
        },
    });

    return [instance];
};

export { parseJwt, login, hasValidToken, getTokens, logout, useApi };
