// Articles API

import { apiCore } from "./utilities/core";
import { format } from "date-fns";

export default  {
    getArticles: async (r, date, text) => {
        return await apiCore.get(
            `/articles?l=he&r=${r}&dt=${format(date, "MM/dd/yyyy hh:mm:ss")}&q=${text}`
        );
    },

    getCategories: async () => {
        return await apiCore.get("/categories?l=he");
    },

    likeArticle: async (articleId) => {
        await apiCore.post(`/users/articles/${articleId}/like`);
    },

    deleteArticle: async (articleId) => {
        await apiCore.delete(`/users/articles/${articleId}`);
    },

    clearFeed: async () => {
        await apiCore.delete(`/users/articles`);
    }
};
