import React from "react";
import {
    IonRouterOutlet,
    IonSplitPane
} from "@ionic/react";

import SideMenu from "./SideMenu";

function SplitPane({ routeComponents }) {
    return (
        <IonSplitPane contentId="main">
            <SideMenu />

            <IonRouterOutlet id="main">
                {routeComponents}
            </IonRouterOutlet>
        </IonSplitPane>
    );
}

export default SplitPane;