import pages from "../../../configuration/pages.json";

import React from "react";
import {
    IonRouterOutlet,
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonIcon,
    IonLabel
} from "@ionic/react";
import {
    personOutline,
    albumsOutline,
    peopleOutline,
    settingsOutline,
    newspaperOutline,
    bookmarkOutline,
} from "ionicons/icons";
import { useTranslation } from "react-i18next";

const icons = {
    personOutline,
    albumsOutline,
    peopleOutline,
    settingsOutline,
    newspaperOutline,
    bookmarkOutline,
};

function Tabs({ routeComponents }) {
    const { t } = useTranslation();

    return (
        <IonTabs>
            <IonRouterOutlet>
                {routeComponents}
            </IonRouterOutlet>
            <IonTabBar slot="bottom">
                {pages.map((appPage, index) => {
                    return (
                        <IonTabButton key={index} tab={index} href={appPage.url}>
                            <IonIcon
                                ios={icons[appPage.iosIcon]}
                                md={icons[appPage.mdIcon]}
                            />
                            <IonLabel>{t(appPage.title)}</IonLabel>
                        </IonTabButton>
                    );
                })}
            </IonTabBar>
        </IonTabs>
    );
}

export default Tabs;