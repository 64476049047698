// Iamges API

import { getToken } from "./utilities/jwt";
import config from "../../configuration";

export default {
    getSiteImageUrl: (siteId) => {
        return `${config.ApiUrl}/media/images/site${siteId}?access_token=${
            getToken().token
        }`;
    },

    getImageUrl: (filename) => {
        return `${config.ApiUrl}/media/images/${filename}?access_token=${
            getToken().token
        }`;
    }
};