// Auth API

import { apiCore, getAuthHeaders } from "./utilities/core";
import { getToken, storeToken, getDecodedToken } from "./utilities/jwt";

const authApi = {
    login: async (username, password) => {
        try {
            const headers = await getAuthHeaders();
            const { data } = await apiCore.post("/login", {
                username,
                password
            }, headers);

            storeToken(data.access_token, data.refresh_token);

            return {
                token: data.access_token,
                refreshToken: data.refresh_token,
            };
        } catch (err) {
            return null;
        }
    }
};

const isAuthenticated = () => {
    const token = getToken();
    return token != null && token.token != null;
};

const getUserDetails = () => {
    if (!isAuthenticated()) return null;
    const decodedToken = getDecodedToken();
    if (decodedToken == null) return null;

    const [, payload] = decodedToken;
    return {
        userId: payload.sub,
        name: payload.name,
        email: payload.email,
    };
};

export { authApi, isAuthenticated, getUserDetails };
