import "./Categories.css";

import React, { useEffect, useState } from "react";
import { IonSlides, IonSlide, IonSkeletonText } from "@ionic/react";
import { Trans } from "react-i18next";
import articlesApi from "../../services/api/articlesAPI";

function Categories({ onCategorySelected }) {
    const [categoriesInitialized, setCategoriesInitialized] = useState(false);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(0);

    useEffect(() => {
        async function getCategories() {
            const { data: categoriesList } = await articlesApi.getCategories();

            setCategories(categoriesList);
            setCategoriesInitialized(true);
        }

        getCategories();
    }, []);

    const handleCategoryClick = (categoryId) => {
        setSelectedCategory(categoryId);
        onCategorySelected(categoryId);
    };

    const getSlideClass = (id) => {
        var className = "categorySlide";
        if (selectedCategory === id) className += " selected";
        return className;
    };

    return (
        <>
            {categories && categoriesInitialized ? (
                categories.length > 0 ? (
                    <div className="categoriesContainer">
                        <IonSlides
                            options={{
                                slidesPerView: "auto",
                                spaceBetween: 25,
                            }}
                        >
                            <IonSlide
                                onClick={() => handleCategoryClick(0)}
                                className={getSlideClass(0)}
                            >
                                <Trans>Categories.All</Trans>
                            </IonSlide>
                            {categories.map((category) => (
                                <IonSlide
                                    key={category.id}
                                    onClick={() =>
                                        handleCategoryClick(category.id)
                                    }
                                    className={getSlideClass(category.id)}
                                >
                                    {category.name}
                                </IonSlide>
                            ))}
                        </IonSlides>
                    </div>
                ) : (
                    <></>
                )
            ) : (
                <IonSkeletonText
                    animated
                    style={{ width: "20%", height: "10px", marginLeft: "10px" }}
                />
            )}
        </>
    );
}

export default Categories;
