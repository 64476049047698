import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { isAuthenticated } from "../../services/api/authAPI";

function ProtectedRoute({
    component: Component,
    children: ChildComponent,
    ...rest
}) {
    const location = useLocation();
    const unProtectedPaths = ["/"];

    return (
        <Route
            {...rest}
            render={(props) => {
                if (isAuthenticated()) {
                    return (
                        (Component && <Component {...props} />) ||
                        ChildComponent
                    );
                } else if (!unProtectedPaths.includes(location.pathname)) {
                    return (
                        <Redirect
                            to={{
                                pathname: "/",
                                state: { from: props.location },
                            }}
                        />
                    );
                }
            }}
        />
    );
}

export default ProtectedRoute;
