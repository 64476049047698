import "./Writers.css";

import AppPage from "../AppPage/AppPage";
import { useTranslation } from "react-i18next";

function Writers() {
    const { t } = useTranslation();

    return <AppPage title={t("Writers.Title")}></AppPage>;
}

export default Writers;
