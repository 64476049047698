import "./ProfilePicture.css";

import React from "react";
import { IonIcon } from "@ionic/react";
import { personCircle } from "ionicons/icons";

const ProfilePicture = ({ size, profilePicture, showRing }) => {
    size = size || 60;

    return (
        <span>
            {(profilePicture && (
                <img
                    alt="profile"
                    src={profilePicture}
                    style={{
                        width: size + "px",
                        height: size + "px",
                        borderRadius: "50%",
                    }}
                    className={showRing ? "ring" : ""}
                />
            )) || (
                <IonIcon
                    icon={personCircle}
                    style={{
                        width: size + "px",
                        height: size + "px",
                    }}
                />
            )}
        </span>
    );
};

export default ProfilePicture;
