import "./PwaInstallationNotification.css";

import React, { useRef } from "react";
import { IonPopover, isPlatform, IonIcon } from "@ionic/react";
import { Trans } from "react-i18next";
import { shareOutline } from "ionicons/icons";

const PwaInstallationNotification = () => {
    const showPopover = isPlatform("ios") && !isPlatform("pwa");
    const popoverRef = useRef(null);

    return (
        (showPopover && (
            <>
                <img
                    className="loginPopoverElement"
                    src=""
                    alt="popover location"
                    onError={(e) => {
                        popoverRef.current.present(e);
                    }}
                />
                <IonPopover
                    ref={popoverRef}
                    cssClass="loginPopover"
                    showBackdrop={false}
                    alignment="center"
                >
                    <div className="loginPopoverContent">
                        <Trans
                            components={{
                                icon: (
                                    <IonIcon icon={shareOutline} size="small" />
                                ),
                            }}
                        >
                            Login.InstallMessage
                        </Trans>
                    </div>
                </IonPopover>
            </>
        )) || <></>
    );
};

export default PwaInstallationNotification;
