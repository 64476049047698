import "./Profile.css";

import AppPage from "../AppPage/AppPage";
import ProfilePicture from "../../components/ProfilePicture/ProfilePicture";
import { getUserDetails } from "../../services/api/authAPI";
import { useTranslation } from "react-i18next";

function Profile() {
    const userDetails = getUserDetails();
    const { t } = useTranslation();

    return (
        <AppPage title={t("Profile.Title")}>
            <div className="profileHeaderContainer">
                <ProfilePicture size="100" showRing={false} />

                <br />

                <div className="profileName">{userDetails.name}</div>
                <div className="profileEmail">{userDetails.email}</div>
            </div>

            <div className="seperator"></div>

            <div className="profileSummeryContainer">
                <div>
                    <span>56</span>
                    <span>Likes</span>
                </div>

                <div>
                    <span>100</span>
                    <span>Organizations</span>
                </div>

                <div>
                    <span>10</span>
                    <span>Sites</span>
                </div>
            </div>
        </AppPage>
    );
}

export default Profile;
