import "./Home.css";

import { useState, useEffect } from "react";
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonIcon,
    IonButton,
    isPlatform,
    IonMenuButton,
    IonButtons,
} from "@ionic/react";
import { menuOutline } from "ionicons/icons";
import { Trans } from "react-i18next";
import Categories from "../../components/Categories/Categories";
import ArticlesList from "../../components/ArticlesList/ArticlesList";
import SearchBar from "../../components/SearchBar/SearchBar";

//new stuff
import ReactDOM from "react-dom";
import articlesApi from "../../services/api/articlesAPI";
import {
    BEFORE,
    AFTER,
    INDEXED_DB_NAME,
    INDEXED_DB_VERSION,
} from "../../logic/consts";
//new stuff - end

function Home(props) {
    const [headerVisible, setHeaderVisible] = useState(false);
    const [articles, setArticles] = useState(null);
    const [lastRetrievedDate, setLastRetrievedDate] = useState(new Date());
    const [searchText, setSearchText] = useState("");

    const getArticles = async (r, date) => {
        const { data: articlesList } = await articlesApi.getArticles(
            BEFORE,
            date,
            searchText
        );

        if (articlesList != null) {
            const newArticlesList =
                r === BEFORE
                    ? [...(articles || []), ...articlesList]
                    : [...articlesList, ...(articles || [])];
            ReactDOM.unstable_batchedUpdates(() => {
                setArticles(newArticlesList);
                if (articlesList.length > 0) {
                    setLastRetrievedDate(
                        new Date(articlesList[articlesList.length - 1].publishDate)
                    );
                }
            });
            //await storeArticles(newArticlesList);
        }
    };

    const handleGetArticles = async (e) => {
        await getArticles(BEFORE, lastRetrievedDate);
        e.target.complete();
    };

    const handleRefresh = async (e) => {
        await getArticles(AFTER, new Date(articles[0].publishDate));
        e.detail.complete();
    };

    const handleArticleDeleted = (article) => {
        const filteredArticles = articles.filter(a => a.id !== article.id);
        setArticles(filteredArticles);
    };
    
    const handleContentScroll = (e) => {
        setHeaderVisible(e.detail.scrollTop && e.detail.scrollTop > 50);
    };

    const handleCategorySelection = (categoryId) => {
        console.log("selected category id: " + categoryId);
    };

    const handleFeedCleared = () => {
        setArticles([]);
    };

    const handleSearch = async (text) => {
        const { data: articlesList } = await articlesApi.getArticles(
            BEFORE,
            new Date(),
            text
        );
        setSearchText(text || []);
        setArticles(articlesList || []);
    };

    useEffect(() => {
        getArticles(BEFORE, lastRetrievedDate);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    //useEffect(() => {}, []);

    return (
        <IonPage id="main">
            {((isPlatform("ios") || headerVisible) && (
                <IonHeader>
                    <IonToolbar className="toolbar">
                        <IonButtons slot="start">
                            <IonMenuButton />
                        </IonButtons>
                        <IonTitle>
                            <Trans>Home.Title</Trans>
                        </IonTitle>
                    </IonToolbar>
                </IonHeader>
            )) || <></>}

            <IonContent
                fullscreen
                scrollEvents
                onIonScroll={handleContentScroll}
            >
                <IonHeader
                    collapse={isPlatform("ios") ? "condense" : null}
                    className="ion-no-border"
                >
                    <IonToolbar>
                        {(isPlatform("android") && (
                            <IonButton fill="clear">
                                <IonIcon slot="start" icon={menuOutline} />
                            </IonButton>
                        )) || <></>}

                        <div className="bigTitle">
                            <Trans>Home.Title</Trans>
                            <span className="subTitle">
                                <Trans>Home.SubTitle</Trans>
                            </span>
                        </div>
                    </IonToolbar>
                    <IonToolbar>
                        <SearchBar onFeedCleared={handleFeedCleared} onSearch={handleSearch} />
                    </IonToolbar>
                    <IonToolbar>
                        <Categories onCategorySelected={handleCategorySelection} />
                    </IonToolbar>
                </IonHeader>

                <ArticlesList articles={articles} onRefresh={handleRefresh} onReachEnd={handleGetArticles} onDelete={handleArticleDeleted} />
            </IonContent>
        </IonPage>
    );
}

export default Home;
