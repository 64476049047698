import "./App.css";
import Login from "./pages/Login/Login";
import Home from "./pages/Home/Home";
import Likes from "./pages/Likes/Likes";
import Article from "./pages/Article/Article";
import Profile from "./pages/Profile/Profile";
import Categories from "./pages/Categories/Categories";
import Writers from "./pages/Writers/Writers";
import Settings from "./pages/Settings/Settings";
import UpdatePage from "./pages/UpdatePage/UpdatePage";
import AppUpdateNotification from "./components/AppUpdateNotification/AppUpdateNotification";

import { useState } from "react";
import {
    IonApp,
    setupIonicReact,
    useIonToast
} from "@ionic/react";
import { Route, Redirect } from "react-router-dom";
import { IonReactRouter } from "@ionic/react-router";
import { UserProvider } from "./context/userContext";
import i18next from "i18next";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import Tabs from "./components/Navigation/Tabs/Tabs";
import SplitPane from "./components/Navigation/SideMenu/SplitPane";
import { getTokens } from "./logic/api";
import useBreakpoint from 'use-breakpoint';
import { useEffect } from "react";
import { configureApiCore, ApiError } from "./services/api/utilities/core";
import { useTranslation } from "react-i18next";
import { logout } from "./logic/api";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./theme/aikhbarVariables.css";

const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280 } // this is defined outside the component otherwise it causes an infinit loop error by the library

setupIonicReact({
    swipeBackEnabled: false
}); // required by Ionic v6 - otherwise the app will not display on screen

function App() {
    const [, setLanguage] = useState(i18next.language);
    const [count, setCount] = useState(0);
    const { breakpoint } = useBreakpoint(BREAKPOINTS, 'mobile');
    const [showToast, dismissToast] = useIonToast();
    const { t } = useTranslation();

    let toastShown = false;
    
    configureApiCore({
        onError: (error) => {
            let errorHeaderKey, errorDescriptionKey;
            switch (error) {
                case ApiError.NetworkError:
                    errorHeaderKey = "NetworkErrors.Connection.Header";
                    errorDescriptionKey = "NetworkErrors.Connection.Message";
                    break;
                case ApiError.SessionExpired:
                    toastShown = false;
                    dismissToast();
                    logout();
                    reloadAppState();
                    break;
                default:
                    errorHeaderKey = `NetworkErrors.E${error}.Header`;
                    errorDescriptionKey = `NetworkErrors.E${error}.Message`;
            }

            let
                errorHeader = t(errorHeaderKey),
                errorDescription = t(errorDescriptionKey);
            
            if (errorHeader && errorDescription &&
                errorHeader !== errorHeaderKey &&
                errorDescription !== errorDescriptionKey)
                showMessageFromApp(
                    errorHeader,
                    errorDescription,
                    [
                        {
                            text: t("Common.Ok"),
                            handler: () => dismissToast(),
                        },
                    ]
                );
        }
    });

    useEffect(() => {
        const element = document.querySelector('ion-app');

        element.addEventListener('touchstart', (e) => {
            if (!e.pageX) return;
            if (e.pageX > 20 && e.pageX < window.innerWidth - 20) return;
            // prevent swipe to navigate gesture
            e.preventDefault();
        });
    });

    const showMessageFromApp = async (header, message, buttons, replaceExisting, duration = 10000) => {
        if (toastShown && replaceExisting) {
            await dismissToast();
            toastShown = false;
        }

        if (toastShown) return;

        toastShown = true;
        showToast({
            duration,
            header,
            message,
            buttons,
            cssClass: 'custom-toast',
            onDidDismiss: () => (toastShown = false)
        });
    };

    const changeLanguage = (language) => {
        setLanguage(language);
    };

    const reloadAppState = (state) => {
        setCount(count + 1);
    };
    
    const userProviderValue = {
        changeLanguage,
        reloadAppState
    };

    let defaultPage = "/login";
    let checkForUpdates = true;
    if (localStorage.getItem("updateVersion")) {
        defaultPage = "/update";
        checkForUpdates = false;
    } else {
        const tokens = getTokens();
        if (tokens?.refreshToken) defaultPage = "home";
    }

    const routeComponents = [
        <ProtectedRoute path="/home" exact><Home /></ProtectedRoute>,
        <ProtectedRoute path="/likes" exact><Likes /></ProtectedRoute>,
        <ProtectedRoute path="/article/:articleId"><Article></Article></ProtectedRoute>,
        <ProtectedRoute path="/profile" exact><Profile /></ProtectedRoute>,
        <ProtectedRoute path="/categories" exact><Categories /></ProtectedRoute>,
        <ProtectedRoute path="/writers" exact><Writers /></ProtectedRoute>,
        <ProtectedRoute path="/settings" exact><Settings /></ProtectedRoute>,
        <Route path="/update" exact><UpdatePage /></Route>,
    ].map((Route, index) => ({ ...Route, key: index }));

    return (
        <UserProvider value={userProviderValue}>
            <IonApp dir={i18next.dir()}>
                <IonReactRouter>
                    {defaultPage === "/login" ?
                        <Route path="/login" exact><Login /></Route>
                        :
                        <>
                            {breakpoint === 'mobile' ?
                                <Tabs routeComponents={routeComponents} />
                                :
                                <SplitPane routeComponents={routeComponents} />
                            }
                        </>
                    }
                    
                    <Redirect to={defaultPage} />
                </IonReactRouter>
            </IonApp>

            {checkForUpdates && <AppUpdateNotification />}
        </UserProvider>
    );
}

export default App;
