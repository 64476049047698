import { useEffect, useRef } from "react";
import {
    createGesture, createAnimation,
    IonItem,
    IonIcon
} from "@ionic/react";

function SwipeDeleteItem({ children: ChildComponent, onComplete, backgroundColor, icon, iconColor, iconSize, ...rest }) {
  const hostElement = useRef(null);
  const innerItem = useRef(null);
  const windowWidth = window.innerWidth;
  let gesture = null;

  useEffect(() => {
    const hostDeleteAnimation = createAnimation()
      .addElement(hostElement.current)
      .duration(200)
      .easing('ease-out')
      .fromTo('opacity', 1, 0)
      .fromTo('height', '48px', '0');

    const options = {
      el: hostElement.current,
      gestureName: 'swipe-delete',
      gesturePriority: 100, // this is set to overcome pull-to-refresh gesture
      onStart: () => {
        innerItem.current.style.transition = '';
      },
      onMove: (ev) => {
        if (ev.deltaX > 0) {
          innerItem.current.style.transform = `translate3d(${ev.deltaX}px, 0, 0)`;
        }

        const borderRadius = 10;
        const shadowSize = 3;
        
        if (ev.deltaX < borderRadius) {
          innerItem.current.style.borderRadius = `${ev.deltaX}px 0px 0px ${ev.deltaX}px`;
        }
        if (ev.deltaX < shadowSize) {
          innerItem.current.style.boxShadow = `-${ev.deltaX}px 0px 0px 0px #00000055`;
        }
      },
      onEnd: (ev) => {
        innerItem.current.style.transition = "0.2s ease-out";
        if(ev.deltaX > 150) {
          innerItem.current.style.transform = `translate3d(${windowWidth}px, 0, 0)`;
          hostDeleteAnimation.play();
          hostDeleteAnimation.onFinish(() => {
            hostElement.current.style.display = 'none';
            if (onComplete) onComplete();
          });
        } else {
          innerItem.current.style.transform = '';
          innerItem.current.style.borderRadius = 0;
          innerItem.current.style.boxShadow = 0;
        }
      }
    };
    
    gesture = createGesture(options);
    gesture.enable();

  }, []);

  return (
    <div ref={hostElement} style={{display: 'grid', direction: 'ltr', backgroundColor}}>
      <div style={{gridColumn: '1', gridRow: '1', display: 'grid', alignItems: 'center'}}>
        <IonIcon icon={icon} style={{marginLeft: '20px', color: iconColor, fontSize: iconSize || '24px' }}></IonIcon>
      </div>
      <IonItem ref={innerItem} style={{gridColumn: '1', gridRow: '1'}}>
        {ChildComponent}
      </IonItem>
    </div>
  );
}

export default SwipeDeleteItem;
