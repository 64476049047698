import "./AppUpdateNotification.css";

import React, { useState, useEffect } from "react";
import { IonModal, IonButton } from "@ionic/react";
import { Trans } from "react-i18next";
import { parseVersion } from "../../logic/version";
import versionsApi from "../../services/api/versionsAPI";
import config from "../../configuration";
import i18next from "i18next";

const AppUpdateNotification = () => {
    const [showModal, setShowModal] = useState(false);
    const [allowSkipping, setAllowSkipping] = useState(true);

    useEffect(() => {
        const checkForUpdates = async () => {
            const { data: version } = await versionsApi.getVersionInformation({
                applicationId: "app.onews.co.il",
                platform: 1,
                currentVersion: config.Version,
            });

            console.log("version", version);

            if (version) {
                const minVersion = parseVersion(version.minVersion);
                const currentVersion = parseVersion(config.Version);

                console.log("minVersion", minVersion);
                console.log("currentVersion", currentVersion);

                const isAllowSkipping = !(
                    minVersion.major > currentVersion.major ||
                    (minVersion.major === currentVersion.major &&
                        minVersion.minor > currentVersion.minor) ||
                    (minVersion.major === currentVersion.major &&
                        minVersion.minor === currentVersion.minor &&
                        minVersion.build > currentVersion.build) ||
                    (minVersion.major === currentVersion.major &&
                        minVersion.minor === currentVersion.minor &&
                        minVersion.build === currentVersion.build &&
                        minVersion.revision > currentVersion.revision)
                );

                if (!isAllowSkipping) setAllowSkipping(false);
                setShowModal(true);
            }
        };

        checkForUpdates();
    }, []);

    const handleUpdate = () => {
        localStorage.setItem("updateVersion", true);
        window.location = "/";
    };

    const handleCancel = () => {
        setShowModal(false);
    };

    return (
        <IonModal
            isOpen={showModal}
            initialBreakpoint={0.43}
            breakpoints={[0, 0.43]}
        >
            <div
                className="updateContent"
                style={{ direction: i18next.dir() === "rtl" ? "rtl" : "ltr" }}
            >
                <h1>
                    <Trans>Update.Title</Trans>
                </h1>
                <p
                    style={{
                        textAlign: i18next.dir() === "rtl" ? "right" : "left",
                        width: "80%",
                        marginLeft: "auto",
                        marginRight: "auto",
                    }}
                >
                    <Trans>Update.SubTitle1</Trans>
                    <br />
                    <br />
                    <Trans>Update.SubTitle2</Trans>
                </p>
                <IonButton onClick={handleUpdate}>
                    <Trans>Update.Update</Trans>
                </IonButton>
                {allowSkipping && (
                    <>
                        <br />
                        <IonButton fill="clear" onClick={handleCancel}>
                            <Trans>Update.Cancel</Trans>
                        </IonButton>
                    </>
                )}
            </div>
        </IonModal>
    );
};

export default AppUpdateNotification;
