import "./Categories.css";

import AppPage from "../AppPage/AppPage";
import { useTranslation } from "react-i18next";

function Categories() {
    const { t } = useTranslation();

    return <AppPage title={t("Categories.Title")}></AppPage>;
}

export default Categories;
