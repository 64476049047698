// Versions API

import { apiCore } from "./utilities/core";

export default  {
    getVersionInformation: async (versionInformation) => {
        return await apiCore.get(
            `/versions?ApplicationId=${versionInformation.applicationId}&Platform=${versionInformation.platform}&CurrentVersion=${versionInformation.currentVersion}`
        );
    }
};