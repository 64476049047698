import "./SearchBar.css";

import React, { useState } from "react";
import { IonIcon, IonGrid, IonRow, IonCol, IonButton, IonSearchbar } from "@ionic/react";
import { searchOutline, optionsOutline } from "ionicons/icons";
import FiltersModal from "../FiltersModal/FiltersModal";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

function SearchBar({ onFeedCleared, onSearch }) {
    const { t } = useTranslation();
    const [showFiltersIcon, setShowFiltersIcon] = useState(true);
    const [clearIconPadding, setClearIconPadding] = useState(false);

    const handleSearchFocus = () => {
        setClearIconPadding(false);
        setShowFiltersIcon(false);
    };

    const handleSearchBlur = (e) => {
        if (e.target.value.length > 0) setClearIconPadding(true);
        setShowFiltersIcon(true);
    };

    const handleChange = (e) => {
        if (onSearch) onSearch(e.target.value);
    };

    return (
        <div className="searchbarContainer">
            <IonSearchbar
                className={"searchBar" + (clearIconPadding ? " searchbar-clear-button-padding" : "")}
                placeholder={t("Search.Placeholder")}
                autocorrect="off"
                enterkeyhint="search"
                showCancelButton="focus"
                onIonBlur={handleSearchBlur}
                onIonFocus={handleSearchFocus}
                onIonChange={handleChange} />
            {showFiltersIcon && (
                <IonIcon
                    id="openFilters"
                    icon={optionsOutline}
                    className={("optionsIcon" + (i18next.dir() === "rtl" ? " rtl" : ""))}/>
            ) || <></>}
            <FiltersModal onFeedCleared={onFeedCleared} />
        </div>
    );
}

export default SearchBar;
