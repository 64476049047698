import "./Likes.css";

import AppPage from "../AppPage/AppPage";
import { useTranslation } from "react-i18next";
import { IonContent } from "@ionic/react";

function Likes() {
    const { t } = useTranslation();

    return <AppPage title={t("Likes.Title")}>
        <IonContent>
        </IonContent>
    </AppPage>;
}

export default Likes;
