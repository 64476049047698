import "./Login.css";

import React, { useState, useRef, useContext } from "react";
import { useHistory } from "react-router";
import {
    IonPage,
    IonContent,
    IonInput,
    IonButton,
    useIonToast,
    IonSpinner,
} from "@ionic/react";
import { Trans, useTranslation } from "react-i18next";
import OnewsLogo from "../../components/OnewsLogo/onewsLogo";
import { authApi } from "../../services/api/authAPI";
import PwaInstallationNotification from "../../components/PwaInstallationNotification/PwaInstallationNotification";
import UserContext from "../../context/userContext";

function Login() {
    const currentUser = useContext(UserContext);
    const [loggingIn, setLoggingIn] = useState(false);
    const history = useHistory();
    const { t } = useTranslation();
    const [showToast, dismissToast] = useIonToast();

    const usernameInput = useRef(null);
    const passwordInput = useRef(null);

    const handleLogin = async () => {
        setLoggingIn(true);

        const authResponse = await authApi.login(
            usernameInput.current.value,
            passwordInput.current.value
        );

        if (authResponse && authResponse.token) {
            currentUser.reloadAppState();
        } else {
            passwordInput.current.value = "";
            setLoggingIn(false);

            showToast({
                duration: 10000,
                buttons: [
                    { text: t("Common.Ok"), handler: () => dismissToast() },
                ],
                message: t("Login.InvalidCredentials"),
            });
        }
    };

    const handleInputFocus = (event, pos) => {
        window.scrollTo(0, 240);
    };

    return (
        <IonPage id="main">
            <IonContent className="loginContent">
                <OnewsLogo size={200} textSize={30} className="loginLogo" />
                <h1 className="loginTitle">
                    <Trans>Login.Title</Trans>
                </h1>
                <h2 className="loginSubTitle">
                    <Trans>Login.SubTitle</Trans>
                </h2>

                <div className="loginFormContainer">
                    <IonInput
                        ref={usernameInput}
                        placeholder={t("Common.Username")}
                        inputmode="email"
                        className="inputField"
                        disabled={loggingIn}
                        onIonFocus={(e) => handleInputFocus(e)}
                    />
                    <IonInput
                        ref={passwordInput}
                        placeholder={t("Common.Password")}
                        type="password"
                        className="inputField"
                        disabled={loggingIn}
                        onIonFocus={(e) => handleInputFocus(e)}
                    />
                    <IonButton
                        expand="block"
                        className="signInButton"
                        onClick={handleLogin}
                        disabled={loggingIn}
                    >
                        {(loggingIn && <IonSpinner name="crescent" />) ||
                            t("Login.SignIn")}
                    </IonButton>
                </div>

                <PwaInstallationNotification />
            </IonContent>
        </IonPage>
    );
}

export default Login;
