import "./ArticlesList.css";

import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import {
    IonList,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonRefresher,
    IonRefresherContent,
} from "@ionic/react";
import { chevronDownCircleOutline } from "ionicons/icons";
import Dexie from "dexie";
import { useTranslation, Trans } from "react-i18next";
import ArticleItem from "../ArticleItem/ArticleItem";
import FeedLoadingItem from "../FeedLoadingItem";
import {
    BEFORE,
    AFTER,
    INDEXED_DB_NAME,
    INDEXED_DB_VERSION,
} from "../../logic/consts";

function ArticlesList({ articles, onRefresh, onReachEnd, onDelete }) {
    const { t } = useTranslation();
    
    return (
        <>
            {onRefresh && (
                <IonRefresher slot="fixed" onIonRefresh={onRefresh} pullFactor={0.5} pullMin={100} pullMax={150}>
                    <IonRefresherContent
                        pullingIcon={chevronDownCircleOutline}
                        pullingText={t("Common.PullToRefresh")}
                        refreshingText={t("Common.Refreshing")}
                        refreshingSpinner="circles"
                    ></IonRefresherContent>
                </IonRefresher>
            ) || <></>}

            <IonList>
                {/* {articles && articlesInitialized ? ( */}
                {articles ? (
                    articles.length > 0 ? (
                        articles.map((article) => (
                            <ArticleItem article={article} key={article.id} onDelete={onDelete} />
                        ))
                    ) : (
                        <div className="feedEmptyMessage">
                            <Trans>ArticlesList.NoResults</Trans>
                        </div>
                    )
                ) : (
                    [...Array(6)].map((x, i) => <FeedLoadingItem key={i} />)
                )}
            </IonList>

            {onReachEnd && (
                <IonInfiniteScroll
                    threshold="100px"
                    onIonInfinite={onReachEnd}>
                    <IonInfiniteScrollContent
                        loadingSpinner="bubbles"
                        loadingText={t("Common.Loading")}
                    ></IonInfiniteScrollContent>
                </IonInfiniteScroll>
            ) || <></>}
        </>
    );
}

export default ArticlesList;
