import "./ArticleItem.css";

import React, { useEffect, useRef } from "react";
import { useHistory } from "react-router";
import { IonRow, IonCol, IonGrid, IonAvatar } from "@ionic/react";
import lottie from "lottie-web";
import { displayDate } from "../../logic/datetime";
import imagesApi from "../../services/api/imagesAPI";
import articlesApi from "../../services/api/articlesAPI";
import { isStrRTL } from "../../logic/languageHelpers";
import SwipeDeleteItem from "../SwipeDeleteItem/SwipeDeleteItem";
import { trashOutline } from "ionicons/icons";

function ArticleItem(props) {
    const history = useHistory();
    const article = props.article;

    const container = useRef(null);
    let anim = null;

    const getPreviewText = (article) => {
        let previewText = article.subTitle ? article.subTitle : article.text;
        if (previewText.length > 0) {
            let previewArr = previewText.split(/\s+/);
            previewText = previewArr.slice(0, 25).join(" ");
            let lastPreviewChar = previewText[previewText.length - 1];
            if (lastPreviewChar === "." || lastPreviewChar === "," || lastPreviewChar === "!")
                previewText = previewText.slice(0, -1);
            previewText += (previewArr.length > 25 ? "..." : "");

            return <div dangerouslySetInnerHTML={{ __html: previewText }}></div>;
        }

        return "";
    };

    const handleArticleClick = (article) => {
        history.push(`/article/${article.id}`, { article: article });
    };

    const handleLikeArticleClick = async (articleId) => {
        if (!article.liked) {
            anim.playSegments([[45, 78]], true);
        } else {
            if (article.liked) anim.goToAndStop(0, true);
        }
        await articlesApi.likeArticle(articleId);
        article.liked = !article.liked;
    };

    const handleArticleDeleted = async (articleId) => {
        await articlesApi.deleteArticle(articleId);
        if (props.onDelete) props.onDelete(articleId);
    };

    useEffect(() => {
        container.current.innerHTML = "";
        anim = lottie.loadAnimation({
            container: container.current,
            renderer: "svg",
            loop: false,
            autoplay: false,
            animationData: require("../../assets/lottie/like.json"),
        });
        if (article.liked) anim.goToAndStop(78, true);
    });

    

    return (
        <SwipeDeleteItem
            backgroundColor="#ff0000"
            icon={trashOutline}
            iconColor="#fff"
            onComplete={() => handleArticleDeleted(article.id)}
            >
            <IonGrid>
                <IonRow>
                    <IonCol size="2">
                        <IonAvatar>
                            <img
                                src={imagesApi.getSiteImageUrl(article?.siteId)}
                                alt="logo"
                                className="articleSiteIcon"
                            />
                        </IonAvatar>
                    </IonCol>
                    <IonCol className="article">
                        <div style={{ direction: isStrRTL(article.title) ? "rtl" : "ltr" }}>
                            <span
                                className="articleTitle"
                                onClick={() => handleArticleClick(article)}
                            >
                                <div dangerouslySetInnerHTML={{ __html: article.title }}></div>
                            </span>
                            <span className="articleWriter">
                                {(article.writer && (
                                    <>
                                        <span dir="ltr">
                                            {article.writer?.name}
                                        </span>
                                        <span
                                            style={{
                                                width: "20px",
                                                display: "inline-block",
                                                textAlign: "center",
                                            }}
                                        >
                                            &#183;
                                        </span>
                                    </>
                                )) || <></>}
                                <span>{displayDate(article.publishDate)}</span>
                            </span>
                            <span
                                className="articleSubTitle"
                                onClick={() => handleArticleClick(article)}
                            >
                                {getPreviewText(article)}
                            </span>
                            <span className="articleCategory">
                                {article.category?.name}
                            </span>
                            <span className="articleKeywords"></span>
                        </div>

                        <div className="articleActionsContainer">
                            <span
                                className="articleLikeButton"
                                ref={container}
                                onClick={() =>
                                    handleLikeArticleClick(article.id)
                                }
                            />{" "}
                        </div>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </SwipeDeleteItem>
    );
}

export default ArticleItem;
