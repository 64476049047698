import "./onewsLogo.css";
import React from "react";

const OnewsLogo = (props) => {
    return (
        <div
            className={props.className}
            style={{
                width: props.size,
                height: props.size,
                position: "relative",
            }}
        >
            <style
                dangerouslySetInnerHTML={{
                    __html: `
                :root {
                    --onewsLogoFontSize: ${props.textSize}px;
                    --onewsLogoSize: ${props.size}px;
                    --onewsLogoHalfSize: calc(var(--size) / 2);
                }`,
                }}
            />

            <div className="onlCircle onlYellowRed">onews</div>
            <div className="onlCircle onlGreenBlue"></div>
        </div>
    );
};

export default OnewsLogo;
