import axios from "axios";
import config from "../../../configuration";
import { isPlatform } from "@ionic/react";
import { getToken, storeToken } from "./jwt";
import FingerprintJS from '@fingerprintjs/fingerprintjs';

let configuration = {};

const ApiError = {
    UnknownError: 0,
    NotFound: 1,
    NetworkError: 2,
    SessionExpired: 3
};

class ApiCore {
    constructor() {
        this._isRefreshing = false;
        this._failedQueue = [];

        this._axiosInstance = axios.create({
            baseURL: config.ApiUrl,
            timeout: 25000,
            contentType: "application/json"
        });

        this._processQueue = (error, token = null) => {
            this._failedQueue.forEach(promise => {
                if (error) promise.reject(error);
                else promise.resolve(token);
            });
        }

        this._axiosInstance.interceptors.request.use(
            function (config) {
                if (!config.headers) {
                    config.headers = {};
                }
                const tokens = getToken();
                config.headers.Authorization = "Bearer " + tokens.token;
                return config;
            },
            function (error) {}
        );

        this._axiosInstance.interceptors.response.use(
            (response) => {
                return response;
            },
            (error) => {
                let apiError = null;
                const originalRequest = error.config;

                if (!error.response) {
                    console.log("ApiCore:: Connection Error");
                    console.log(`ApiCore:: ${error.response}`);
                    apiError = ApiError.NetworkError;
                } else if (error.response.status === 401 && !originalRequest._retry) {
                    if (this._isRefreshing) {
                        // if a request is already rejected and asking for a new token (those isRefreshing)
                        // then don't further process the current request, create a new promise in the
                        // failed queue where it's resolved state would recall the original request.
                        return new Promise((resolve, reject) => {
                            console.log("ApiCore:: Request is not authorized but another request had already asked for a new token");
                            this._failedQueue.push({resolve, reject});
                        })
                        .then(token => {
                            console.log("ApiCore:: Obtained a new token. Resending a rejected request");
                            return this._axiosInstance(originalRequest);
                        })
                        .catch (error => {
                            console.log("ApiCore:: Failed to obtain new token for a failed request. Aborting original request.");
                            if (configuration.onError)
                                configuration.onError(ApiError.SessionExpired);
                            return Promise.reject(error);
                        });
                    }

                    console.log("ApiCore:: Unauthorized. Attempting to refresh token");
                    originalRequest._retry = true;
                    this._isRefreshing = true; // mark that there is a request for refreshing the token, so other request will be pending on quque until new token is available

                    const tokens = getToken();
                    
                    return getAuthHeaders().then((headers) => {
                        return new Promise((resolve, reject) => {
                            this._axiosInstance
                                .post("/login", { RefreshToken: tokens.refreshToken }, { headers: headers })
                                .then((response) => {
                                    console.log("ApiCore:: Token refreshed", response);
                                    storeToken(response.data.access_token, response.data.refresh_token);
                                    originalRequest.headers["Authorization"] = `Bearer ${response.data.access_token}`;
                                    originalRequest.Authorization = `Bearer ${response.data.access_token}`;
                                    this._processQueue(null, response.data.access_token);
                                    resolve(this._axiosInstance(originalRequest));
                                })
                                .catch((error) => {
                                    this._processQueue(error, null);
                                    reject(error);
                                })
                                .finally(() => {
                                    this._isRefreshing = false;
                                });
                        });
                    });
                } else {
                    console.log(`ApiCore:: Error ${error.response.status}`);
                    apiError = error.response.status;
                }

                if (configuration.onError)
                    configuration.onError(apiError);
                
                return Promise.reject(error);
            }
        );
    }

    async get(url, options, headers) {
        console.log("ApiCore:: get", url, options, headers);
        return await this._axiosInstance.get(url, {
            headers: headers,
            ...options,
        });
    }

    async post(url, data, headers) {
        console.log("ApiCore:: post", url, data, headers);
        return await this._axiosInstance.post(url, data, {
            headers: headers,
        });
    }

    async delete(url, data, headers) {
        console.log("ApiCore:: delete", url, data, headers);
        return await this._axiosInstance.delete(url, data, {
            headers: headers,
        });
    }
}

const apiCore = new ApiCore();

const configureApiCore = (config) => {
    configuration = config;
};

const getAuthHeaders = async () => {
    let fingerprint = localStorage.fingerprint;
    if (!fingerprint) {
        console.log("Fingerprint not found. Generating...");
        const fpPromise = await FingerprintJS.load();
        const generatedFingerprint = await fpPromise.get();
        fingerprint = generatedFingerprint.visitorId;
        localStorage.fingerprint = fingerprint;
    }

    let app = "Onews Website";
    if (isPlatform("pwa")) app = "Onews PWA";

    return {
        "X-ON-DevFingerprint": fingerprint,
        "X-ON-DevApp": app,
    };
};

export { apiCore, configureApiCore, ApiError, getAuthHeaders };
