import "./FiltersModal.css";

import React, { useState, useRef, useEffect } from 'react';
import { IonButton, IonButtons, IonContent, IonHeader, IonList, IonItem, IonLabel, IonModal, IonTitle, IonToolbar, useIonAlert, IonDatetimeButton, IonDatetime } from "@ionic/react";
import { useTranslation, Trans } from "react-i18next";
import articlesApi from "../../services/api/articlesAPI";

function FiltersModal({ onFeedCleared }) {
    const modal = useRef(null);
    const { t } = useTranslation();
    const [showClearFeedAlert] = useIonAlert();

    const handleApply = () => {
        modal.current?.dismiss();
    };

    const handleCancel = () => {
        modal.current?.dismiss();
    };

    const handleClearFeed = () => {
        showClearFeedAlert({
            header: t('Home.FiltersModal.ClearFeedAlert.Title'),
            message: t('Home.FiltersModal.ClearFeedAlert.Message'),
            buttons: [
              t('Common.Cancel'),
              { text: t('Common.Ok'), handler: clearFeed },
            ]
        });
    };

    const clearFeed = async () => {
        await articlesApi.clearFeed();
        if (onFeedCleared) onFeedCleared();
        modal.current?.dismiss();
    };

    return (
        <IonModal ref={modal} trigger="openFilters" canDismiss={true} presentingElement={document.getElementById("main")}>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton onClick={handleCancel}><Trans>Common.Cancel</Trans></IonButton>
                    </IonButtons>
                    <IonTitle>
                        <Trans>Home.FiltersModal.Title</Trans>
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={handleApply}><Trans>Common.Apply</Trans></IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent class="ion-padding">
                <div style={{padding: "10px"}}>
                    You can use the below filters to filter out results in your feed according to your needs.
                </div>

                <span className="settingsTitle">Date range</span>
                <IonList className="settingsContainer">
                    <IonItem detail={true}>
                        <IonLabel>Start Date</IonLabel>
                        <IonLabel slot="end"></IonLabel>
                    </IonItem>
                    <IonItem detail={true}>
                        <IonLabel>End Date</IonLabel>
                        <IonLabel slot="end"></IonLabel>
                    </IonItem>
                </IonList>

                <span className="settingsTitle">Sites and Categories</span>
                <IonList className="settingsContainer">
                    <IonItem detail={true}>
                        <IonLabel>Sites</IonLabel>
                        <IonLabel slot="end">All</IonLabel>
                    </IonItem>
                    <IonItem detail={true}>
                        <IonLabel>Categories</IonLabel>
                        <IonLabel slot="end">All</IonLabel>
                    </IonItem>
                </IonList>

                <IonButton onClick={handleClearFeed} expand="block" fill="solid" color="danger" style={{marginTop: "40px"}}>
                    <Trans>Home.FiltersModal.ClearFeed</Trans>
                </IonButton>
            </IonContent>
        </IonModal>
    );
}

export default FiltersModal;
