function parseVersion(version) {
    const result = {};
    const parts = version.split(".");

    if (parts.length > 0) result.major = parseInt(parts[0]);
    if (parts.length > 1) result.minor = parseInt(parts[1]);
    if (parts.length > 2) result.build = parseInt(parts[2]);
    if (parts.length > 3) result.revision = parseInt(parts[3]);

    return result;
}

export { parseVersion };
